import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import MaterialTable, {MTableToolbar} from 'material-table';
import makeStyles from '@mui/styles/makeStyles';
import buttons from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.js';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardIcon from '../../components/Card/CardIcon';
import {Alert, Grid, MenuItem, Select, Snackbar, Typography} from "@mui/material"
import  { EvStation } from '@mui/icons-material';

import useAPI from '../../utils/useAPI';
import { AuthContext } from '../Auth/AuthContext';
import { Permissions } from '../Auth/Permissions';
import EVChargerService from '../../services/EVChargerService';
import LocationService from '../../services/LocationService';
import CardHeader from "../../components/Card/CardHeader"
import Loading from '../../components/Loading';
import { Modules, isModuleDisabled } from 'config';

const useStyles = makeStyles((theme) => ({
  ...buttons,
  customToolbarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    marginRight: theme.spacing(2),
    fontSize: 16,
  },
}));

export default function EVChargers() {
  const api = useAPI();
  const navigate = useNavigate();
  const classes = useStyles();
  const { hasPermission } = useContext(AuthContext);

  const [locations, setLocations] = useState(null);
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [evChargers, setEVChargers] = useState(null);
  const [showSurfaceSpots, setShowSurfaceSpots] = useState(false);
  const [showCarouselSpots, setShowCarouselSpots] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState(null);

  useEffect(() => {
    if (!hasPermission(Permissions.VIEW_EV_CHARGERS) || isModuleDisabled(Modules.EVChargers)) {
      navigate('/');
    }
  }, [])

  useEffect(() => {
    EVChargerService.init(api);
    LocationService.init(api);
    fetchLocations();
  }, [api])

  const fetchLocations = async () => {
    try {
      const locations = await LocationService.getLocations();
      setLocations(locations);
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    if (locations?.length > 0) {
      setSelectedLocationId(locations[0].id);
    }
  }, [locations]);

  const handleLocationChange = (event) => {
    const locationId = event.target.value;
    setSelectedLocationId(locationId);
  };

  useEffect(() => {
    if (selectedLocationId !== '') {
      fetchEVChargers();
    }
  }, [selectedLocationId]);

  const fetchEVChargers = async () => {
    try {
      const res = await EVChargerService.fetchChargers(selectedLocationId);
      const transformed = res.map(transformCharger);
      setEVChargers(transformed);
      if (hasSurfaceSpots(res)) {
        setShowSurfaceSpots(true);
        if (!hasCarousels(res)) {
          setShowCarouselSpots(false);
        }
      }
    } catch (err) {
      alert(err);
    }
  }

  const hasSurfaceSpots = (chargers) => {
    return chargers.some(c => !!c.connector.parking_spot);
  }

  const hasCarousels = (chargers) => {
    return chargers.some(c => !!c.carousel);
  }

  const Colors = {
    white: '#F9F6F1',
    black: '#130602',
    red: '#F0B8A2',
    green: '#A0DFC2',
    blue: '#89B3C8',
    yellow: '#F9E0A6',
  }
  const StatusGroups = {
    available: 'available',
    connected: 'connected',
    offline: 'offline',
    faulted: 'faulted',
  }
  const ConnectorStatuses = {
    available: {
      code: 'available',
      group: StatusGroups.available,
    },
    preparing: {
      code: 'preparing',
      group: StatusGroups.connected,
    },
    charging: {
      code: 'charging',
      group: StatusGroups.connected,
    },
    suspendedev: {
      code: 'suspendedev',
      group: StatusGroups.connected,
    },
    suspendedevse: {
      code: 'suspendedevse',
      group: StatusGroups.offline,
    },
    finishing: {
      code: 'finishing',
      group: StatusGroups.connected,
    },
    reserved: {
      code: 'reserved',
      group: StatusGroups.offline,
    },
    unavailable: {
      code: 'unavailable',
      group: StatusGroups.offline,
    },
    faulted: {
      code: 'faulted',
      group: StatusGroups.faulted,
    },
    empty: {
      code: '',
      group: StatusGroups.offline,
    },
  };

  function getStatusFromCode(code) {
    return Object.values(ConnectorStatuses).find(status => status.code === code);
  }

  const StatusText = {
    available: 'Available',
    inUse: 'In Use',
    error: 'Error',
  }

  const Description = {
    fullyCharged: 'Fully charged',
    chargerOfflineWithCar: 'Charger is offline (with car)',
    poweredDownWithCar: 'Powered down (with car)',
    chargerFault: 'Charger fault detected',
    chargerFaultWithCar: 'Charger fault detected (with car)',
    charging: 'Charging',
    chargingUnclear: 'Charging status unclear (PLC is offline)',
    withCar: '(with car)',
  }

  const transformCharger = (c) => {
    const { connector, vehicle, powered } = c;
    let carousel = '', pallet = '', surfaceSpot = '';
    if (connector.parking_spot) {
      const spotName = connector.parking_spot.name;
      const zoneName = connector.parking_spot.parking_zone?.name;
      const lotName = connector.parking_spot.parking_lot.name;
      surfaceSpot = `${lotName}: ${zoneName ? zoneName + ': ' : ''}${spotName}`;
    } else {
      carousel = connector.carousel.nickname;
      pallet = `${connector.pallet_number}`;
    }
    let statusCode = connector.status.code;
    const status = getStatusFromCode(statusCode);
    const statusGroup = status.group;

    let bgColor = Colors.white;
    let statusText = '';
    let descriptionText = '';
    let vehicleFullyCharged = vehicle?.charging_status === 'FULL';

    if (statusGroup === StatusGroups.offline) {
      if (powered === true) { // and not 'unknown'
        if (!vehicle) {
          statusText = StatusText.available;
          bgColor = Colors.white;
        } else if (vehicleFullyCharged) {
          statusText = StatusText.inUse;
          descriptionText = Description.fullyCharged;
          bgColor = Colors.green;
        } else {
          statusText = StatusText.error;
          descriptionText = Description.chargerOfflineWithCar;
          bgColor = Colors.red;
        }
      } else { // powered is false or 'unknown'
        if (!vehicle) {
          statusText = StatusText.available;
          bgColor = Colors.white;
        } else if (vehicleFullyCharged) {
          statusText = StatusText.inUse;
          descriptionText = Description.fullyCharged;
          bgColor = Colors.green;
        } else if (powered === 'unknown') {
          statusText = StatusText.inUse;
          descriptionText = Description.poweredDownWithCar;
          bgColor = Colors.yellow;
        } else {
          statusText = StatusText.inUse;
          descriptionText = Description.poweredDownWithCar;
          bgColor = Colors.yellow;
        }
      }
    } else if (statusGroup === StatusGroups.faulted) {
      statusText = StatusText.error;
      descriptionText = vehicle ? Description.chargerFaultWithCar : Description.chargerFault;
      bgColor = Colors.red;
    } else if (statusGroup === StatusGroups.available) {
      statusText = StatusText.available;
      bgColor = Colors.white;
      if (vehicle) {
        descriptionText = Description.withCar;
      }
    } else if (!vehicle) { // We have seen charger in "preparing" status (i.e. seemingly connected) but with no car
      statusText = StatusText.available;
      bgColor = Colors.white;
    } else { // Definitely has a vehicle
      statusText = StatusText.inUse;
      if (vehicleFullyCharged) {
        descriptionText = Description.fullyCharged;
        bgColor = Colors.green;
      } else if (powered === true) {
        descriptionText = Description.charging;
        bgColor = Colors.blue;
      } else if (powered === 'unknown') {
        descriptionText = Description.chargingUnclear;
        bgColor = Colors.blue;
      } else {
        descriptionText = Description.poweredDownWithCar;
        bgColor = Colors.yellow;
      }
    }
    return {
      carousel,
      pallet,
      surfaceSpot,
      status: statusText,
      description: descriptionText,
      bgColor,
      textColor: Colors.black,
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbarMessage(null);
  }

  return (
    <GridContainer>
      {locations && locations.length > 1 ?
        <Grid container alignItems="center" className={classes.customToolbarContainer}>
          <Grid item>
            <Typography variant="h6" className={classes.label}>
              Select Location:
            </Typography>
          </Grid>
          <Grid item>
            <Select
              label="Select Location"
              value={selectedLocationId}
              onChange={handleLocationChange}
            >
              {locations.map((loc) => {
                return <MenuItem key={loc.id} value={loc.id}>{loc.name}</MenuItem>
              })}
            </Select>
          </Grid>
        </Grid> : null}

      <GridItem xs={12}>
        <Card>
          <Snackbar open={!!snackbarMessage} autoHideDuration={60000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
          {evChargers ?
            <MaterialTable
              title='EV Chargers'
              columns={[
                ...(showCarouselSpots ? [
                  { title: 'Carousel', field: 'carousel', cellStyle: {width: '10%'}},
                  { title: 'Pallet', field: 'pallet', type: 'numeric', cellStyle: {width: '15%'}},
                ] : []),
                ...(showSurfaceSpots ? [{ title: 'Surface Spot', field: 'surfaceSpot' }] : []),
                { title: 'Status', field: 'status' },
                { title: 'Description', field: 'description' },
              ]}
              data={evChargers}
              components={{
                Toolbar: (props) => (
                  <div style={{ alignItems: 'center', paddingRight: '0%' }}>
                    <CardHeader color='primary' icon>
                      <CardIcon color='rose'>
                        <EvStation style={{ color: 'white' }} />{' '}
                      </CardIcon>
                    </CardHeader>
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
              options={{
                search: true,
                actionsColumnIndex: 0,
                debounceInterval: 500,
                sorting: true,
                pageSizeOptions: [10, 20, 50, 100],
                pageSize: 20,
                rowStyle: rowData => ({
                  color: rowData.textColor,
                  backgroundColor: rowData.bgColor,
                  fontWeight: 400,
                  fontSize: 16,
                })
              }}
            /> :
            <Loading/>
          }
        </Card>
      </GridItem>
    </GridContainer>
  );
}
